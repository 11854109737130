import { index, store, update, destroy } from '../../services/categories/categories'
import Vue from 'vue'
export const categories = {

  namespaced: 'categories',

  state: {
    data: {},
    pagination: {},
    details: {},
    loading: true,
    error: null
  },

  actions: {

    index ({ state, commit }, { page, name }) {
      commit('setLoading', true)
      index(page, name)
        .then(response => {
          commit('setData', response.data.categories)
          commit('setPagination', response.data.categories)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    store ({ state, commit, dispatch }, data) {
      commit('setLoading', true)
      store(data)
        .then(response => {
          commit('setDetails', response.data.category)
          commit('setLoading', false)
          Vue.toasted.success('Categoria cadastrada com sucesso!')
          dispatch('index', { page: 1 })
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao cadastrar a categoria!')
        })
    },

    update ({ state, commit, dispatch }, { id, data }) {
      commit('setLoading', true)
      update(id, data)
        .then(response => {
          commit('setDetails', response.data.category)
          commit('setLoading', false)
          Vue.toasted.success('Categoria editada com sucesso!')
          dispatch('index', { page: 1 })
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao editar a categoria!')
        })
    },

    destroy ({ state, commit, dispatch }, data) {
      commit('setLoading', true)
      destroy(data)
        .then(response => {
          commit('setDetails', response.data.category)
          commit('setLoading', false)
          Vue.toasted.success('Categoria excluída com sucesso!')
          dispatch('index', { page: 1 })
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao excluir a categoria!')
        })
    }

  },

  mutations: {

    setData (state, categories) {
      state.data = categories
    },

    setPagination (state, paginate) {
      state.pagination = paginate
    },

    setDetails (state, category) {
      state.details = category
    },

    setLoading (state, status) {
      state.loading = status
    },

    setError (state, error) {
      if (error.status === 401) {
        localStorage.clear()
        window.location.href = './login'
      }
    }

  },

  getters: {}

}
